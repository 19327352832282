<template>
  <div class="dashboard">
    <span class="select-field">
      Network selection
    </span>
    <Chip :enabled="groupByName"
          value="Group by network Name"
          class="o-button--shadows group-by-button"
          @click.native="changeGroupBy" />
    <SelectField v-model="selectedNetwork"
                 :options="networks"
                 :showValue="!groupByName"
                 class="network-selector"
                 color="white" />
    <div class="data">
      <div v-for="d in data"
           :key="d.name"
           class="row data__row">
        <span :class="dataClass">
          {{ d.label }}
        </span><span class="data__value col">
          {{ d.value ? `${d.value.toFixed(2)} ${d.unit}` : 'No data' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SelectField from '@/app/components/inputs/select-field.vue';
import heatmapMixin from './heatmap.js';
import Chip from '@/app/components/ui/chip.vue';

export default {
  name: 'WifiDashboard',
  components: { SelectField, Chip },
  mixins: [heatmapMixin],
  props: {
    sidebarExpanded: {
      type: Boolean,
      default: false,
    },
    global: {
      type: Object,
      default: null,
    },
    values: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: [
        {
          label: 'Rssi',
          name: 'wifi.rssi',
          value: undefined,
          unit: 'db',
        },
      ],
      networks: [],
      selectedNetwork: '',
      creatingData: false,
      groupByName: false,
    };
  },
  computed: {
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    dataClass() {
      return this.sidebarExpanded ? 'data__label col-1' : 'data__label col-5';
    },
  },
  watch: {
    values: {
      handler(val) {
        this.initData();
      },
      deep: true,
    },
    selectedNetwork(val) {
      if (!this.creatingData) this.fillCanvas();
      const network = this.networks.find(n => n.value === this.selectedNetwork);
      if (network) this.$emit('input', { label: network.label, value: this.data[0].value });
      else this.$emit('input', null);
    },
    'currentOapp.id'(val) {
      if (val && !val.startsWith('networkMapper')) {
        this.heatmaps = [];
        this.selectedNetwork = '';
      }
    },
    selectedBuilding(val) {
      if (val && !this.creatingData && this.selectedNetwork) this.fillCanvas();
    },
    groupByName(val) {
      if (!this.creatingData && this.selectedNetwork) this.fillCanvas();
    },
  },
  methods: {
    capitalize(s) {
      if (!s || s.length === 0) return s;
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    initData() {
      if (this.values && Object.keys(this.values).length) {
        if (!this.selectedNetwork || !Object.keys(this.values).find(v => this.values[v][0].bssid === this.selectedNetwork))
          this.selectedNetwork = Object.keys(this.values)[0];
        else {
          this.networks = Object.keys(this.values).map(v => (v = { label: this.values[v][0].ssid, value: v }));
          this.fillCanvas();
        }
        this.networks = Object.keys(this.values).map(v => (v = { label: this.values[v][0].ssid, value: v }));
      } else {
        this.destroyHeatmaps();
        this.networks = [];
        this.data[0].value = undefined;
      }
    },
    fillCanvas() {
      this.creatingData = true;
      this.destroyHeatmaps();
      if (this.values && Object.keys(this.values).length) {
        if (this.groupByName) {
          const ssid = this.networks.find(n => n.value === this.selectedNetwork).label;
          const values = [];
          Object.keys(this.values).forEach(v => {
            if (this.values[v][0].ssid === ssid) values.push(...this.values[v]);
          });
          this.data[0].value =
            values.reduce((a, b) => {
              return a + b.rssi;
            }, 0) / values.length;
          if (this.selectedBuilding) setTimeout(() => this.createAllFloorsHeatmap(values), this.canvasReady() ? 0 : 1000);
        } else {
          this.data[0].value =
            this.values[this.selectedNetwork].reduce((a, b) => {
              return a + b.rssi;
            }, 0) / this.values[this.selectedNetwork].length;
          if (this.selectedBuilding)
            setTimeout(() => this.createAllFloorsHeatmap(this.values[this.selectedNetwork]), this.canvasReady() ? 0 : 1000);
        }
      }
      this.creatingData = false;
    },
    canvasReady() {
      const canvas = document.getElementById(`canvas${0}`);
      return canvas ? true : false;
    },
    changeGroupBy() {
      if (this.groupByName) this.networks = Object.keys(this.values).map(v => (v = { label: this.values[v][0].ssid, value: v }));
      else
        this.networks = Object.keys(this.values)
          .filter((v, i, a) => a.findIndex(arr => this.values[arr][0].ssid === this.values[v][0].ssid) === i)
          .map(v => (v = { label: this.values[v][0].ssid, value: v }));
      this.groupByName = !this.groupByName;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.dashboard
  position relative
  padding-top 15px
  .select-field
    position absolute
    top 8px
    left 10px
    font-weight 700
    font-size $fs-body
  .group-by-button
    margin 15px 0 0 10px
    font-size $fs-body
  .network-selector
    margin 10px 10px 0px 10px
  .data
    padding 20px
    .data__row
      margin-left 10px
      padding 3px
    .data__label
      font-weight 700
      font-size $fs-h3
    .data__value
      font-size $fs-h3
  .graph-card
    margin-bottom $border-thick
    max-width 400px
    width 100%
    &:last-child
      margin-bottom 0
  .graph-card
    max-width 2000px
</style>
