import simpleheat from 'simpleheat';

export default {
  data() {
    return {
      heatmaps: [],
    };
  },
  computed: {
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
  },
  methods: {
    createHeatMap(floorId, max, data = []) {
      const canvas = document.getElementById(`canvas${floorId}`);
      const heat = simpleheat(canvas);
      const floor = document.getElementById(`floor${floorId}`);
      const svgData = [];
      data.forEach(p => {
        const point = this.svgToScreenXY(p[0], p[1], floor.firstElementChild);
        svgData.push([point.x, point.y, p[2]]);
      });
      heat.data(svgData);
      heat.max(max);
      heat.radius(75, 125);
      heat.draw();
      this.heatmaps.push({ floorId, heatmap: heat, data });
    },
    svgToScreenXY(x, y, svg) {
      const point = svg.createSVGPoint();
      const matrix = svg.getCTM();
      point.x = x;
      point.y = y;
      return point.matrixTransform(matrix);
    },
    createAllFloorsHeatmap(values) {
      this.heatmaps = [];
      this.currentFloors.forEach((f, idx) => {
        const networks = values.filter(v => v.floorUuid === f.uuid);
        if (networks.length) this.createHeatMap(idx, 47, networks.map(n => (n = [n.x, n.y, 100 + n.rssi])));
      });
    },
    destroyHeatmaps() {
      this.heatmaps.forEach(h => {
        const canvas = document.getElementById(`canvas${h.floorId}`);
        if (canvas) canvas.getContext('2d').clearRect(0, 0, canvas.clientWidth, canvas.height);
      });
      this.heatmaps = [];
    },
    addPoint(floorId, point) {
      const heat = this.heatmaps.find(h => {
        h.floorId === floorId;
      });
      if (heat) heat.add(point);
    },
    updateData(floorId, data) {
      const heatmap = this.heatmaps.find(h => h.floorId === floorId);
      if (heatmap) {
        const heat = heatmap.heatmap;
        heat.clear();
        heat.data(data);
        heat.draw();
      }
    },
  },
};
